<template src="./ResetPassword.html"></template>
<style scoped lang="scss" src="./ResetPassword.scss"></style>

<script>
import ResetPasswordForm from "../../../../components/form/reset-password/ResetPasswordForm";
import {ServiceFactory} from "@/services";

const AuthService = ServiceFactory.get('auth')

export default {
  name: 'ResetPassword',
  components: {ResetPasswordForm},
  data() {
    return {
      isLoading: false,
      error: ''
    }
  },
  async created() {
    this.token = this.$route.query.token || ''
    this.email = this.$route.query.email || ''
    // await this.verifyPageResetPassword()
  },
  methods: {
    async onSubmit(data) {
      data.token = this.token
      data.email = this.email
      this.isLoading = true
      const user = await AuthService.resetPassword(data).catch((error) => {
        this.error = error
        return null
      })
      this.isLoading = false

      if (user.length == 0) {
        this.error = ''
        this.$notify.success({
          message: 'パスワードの再設定が完了しました。',
          showClose: false
        });
        await this.$router.push({name: 'routes.auth.login'})
      }
    }
  },
}
</script>
