<template src="./ResetPasswordForm.html"></template>
<style scoped lang="scss" src="./ResetPasswordForm.scss"></style>

<script>
export default {
  name: 'ResetPasswordForm',
  props: {
    isLoading: {
      require: true,
      default: false,
      type: Boolean
    },
    error: {
      require: false,
      default: '',
    }
  },
  data: function () {
    return {
      data: {
        password: '',
        passwordConfirmation: '',
      },
      rules: {
        password: [
          { required: true, message: '必須項目です。', trigger: 'change' },
        ],
        passwordConfirmation: [
          { required: true, message: '必須項目です。', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    async onSubmit() {
      const valid = await this.$refs.form.validate()
      if (valid)
        this.$emit('submit', this.data)
    },
  }
}
</script>